'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useState } from 'react';
import { wrapper, header, details, content, } from './banner.css.js';
import { Icon } from '../icon/index.js';
import { TextBody } from '../text/index.js';
import { IconButtonTertiary } from '../button/index.js';
const defaultIcon = {
    neutral: 'state-info',
    info: 'state-info',
    error: 'state-error',
    success: 'state-success',
    warning: 'state-warning',
};
const defaultTitle = {
    neutral: undefined, // no default title for neutral
    info: 'Info',
    error: 'Error',
    success: 'Success',
    warning: 'Warning',
};
export const Banner = ({ type = 'neutral', icon, title, children, expandable = false, testId, hideIcon = false, className, }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (_jsxs("div", { className: clsx([
            className,
            wrapper({
                type,
            }),
        ]), "data-testid": testId, children: [_jsxs("div", { className: header, children: [!hideIcon && _jsx(Icon, { symbol: icon || defaultIcon[type] }), !title && type !== 'neutral' && (_jsx("span", { style: { flexGrow: 1 }, children: _jsx(TextBody, { tag: "span", weight: "medium", size: "medium", children: defaultTitle[type] }) })), title && (_jsx("span", { style: { flexGrow: 1 }, children: _jsx(TextBody, { tag: "span", weight: "medium", size: "medium", children: title }) })), expandable && (_jsx(IconButtonTertiary, { color: type === 'neutral' ? 'inherit' : type, label: "Toggle Details", icon: isExpanded ? 'caret-up' : 'caret-down', size: "small", onTrigger: () => setIsExpanded(!isExpanded) }))] }), children && (_jsx("div", { className: details({ expanded: isExpanded || !expandable }), children: _jsx("div", { className: content, children: typeof children === 'string' ? (_jsx(TextBody, { size: "medium", children: children })) : (children) }) }))] }));
};
